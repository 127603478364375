import React, { useState, useEffect, useContext } from 'react'
import createAuth0Client from '@auth0/auth0-spa-js'
import Cookies from 'js-cookie'

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname)
// const DEFAULT_REDIRECT_CALLBACK = (appState) => {
//   history.push(appState && appState.returnTo ? appState.returnTo : window.location.pathname)
// }

export const Auth0Context = React.createContext()
export const useAuth0 = () => useContext(Auth0Context)
export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState()
  const [user, setUser] = useState()
  const [auth0Client, setAuth0] = useState()
  const [loading, setLoading] = useState(true)
  const [popupOpen, setPopupOpen] = useState(false)

  useEffect(() => {
    const initAuth0 = async () => {
      const prms = window.location.search
      const auth0FromHook = await createAuth0Client(initOptions)
      setAuth0(auth0FromHook)
      // console.log('In initAuth0: ', prms)
      Cookies.set('x-rdr-uri', window.location.href.split('?')[0])
      if (prms.includes('shop')) {
        Cookies.set('x-uri-prms', prms)
        console.log('Set cookie, ', Cookies.get('x-uri-prms'))
      }
      if (
        window.location.search.includes('code=') &&
        window.location.search.includes('state=') &&
        !window.location.search.includes('shop=') &&
        !window.location.pathname.includes('hubspot') &&
        !window.location.pathname.includes('facebook')
        // (!window.location.pathname.includes('auth') ||
        //   !window.location.pathname.includes('shopify'))
      ) {
        const { appState } = await auth0FromHook.handleRedirectCallback()
        onRedirectCallback(appState)
      }

      const _isAuthenticated = await auth0FromHook.isAuthenticated()
      setIsAuthenticated(_isAuthenticated)

      if (_isAuthenticated) {
        const _user = await auth0FromHook.getUser()
        setUser(_user)
      }

      setLoading(false)
    }

    initAuth0()
    // eslint-disable-next-line
  }, [])

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true)
    try {
      await auth0Client.loginWithPopup(params)
    } catch (error) {
      console.error(error)
    } finally {
      setPopupOpen(false)
    }
    const _user = await auth0Client.getUser()
    setUser(_user)
    setIsAuthenticated(true)
  }

  const handleRedirectCallback = async () => {
    setLoading(true)
    await auth0Client.handleRedirectCallback()
    const _user = await auth0Client.getUser()
    setLoading(false)
    setIsAuthenticated(true)
    setUser(_user)
  }
  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) =>
          auth0Client.loginWithRedirect({
            ...p,
            redirect_uri: Cookies.get('x-rdr-uri')
              ? Cookies.get('x-rdr-uri')
              : window.location.href,
          }),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => auth0Client.logout(...p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  )
}
