import React, { useState, useEffect, useContext, useRef } from 'react'
import styles from './style.module.scss'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { Breadcrumb, Tooltip } from 'antd';
import { BreadcrumbContext, BreadcrumbProvider } from 'Breadcrumb';
import { hash } from 'immutable';
import StickyBox from 'react-sticky-box'

function hashCode(string) {
  var hash = 0;
  for (var i = 0; i < string.length; i++) {
    var code = string.charCodeAt(i);
    hash = ((hash << 5) - hash) + code;
    hash = hash & hash;
  }
  return hash.toString();
}

const Breadcrumbs = () => {
  let location = useLocation()
  let history = useHistory()
  const [breadcrumbState, setBreadcrumbState] = useState([{ title: 'Home', path: '/' }])

  const getDisplayText = (value) => {
    if (value.length > 25) {
      return value.slice(0, 25) + '...'
    } else {
      return value
    }
  }

  useEffect(() => {
    const currBreadcrumbs = JSON.parse(localStorage.getItem('breadcrumbHistory') || '{}')
    const hash = location.pathname

    if (currBreadcrumbs && currBreadcrumbs[hash]) {
      setBreadcrumbState(currBreadcrumbs[hash])
    } else {
      setBreadcrumbState([{ title: 'Home', path: '/' }])
    }

  }, [location])

  const renderBreadcrumbs = (arr) => {

    const breadcrumbItems = arr?.map((breadcrumb, index) => {
      const title = breadcrumb.title
      const path = breadcrumb.path
      return (
        <Tooltip title={title.length > 25 ? title : null} placement="bottom">
          <Breadcrumb.Item key={path}>
            <Link to={path}>{getDisplayText(title)}</Link>
          </Breadcrumb.Item>
        </Tooltip>
      );
    })

    return breadcrumbItems
  }

  return (
      <div className={styles.breadcrumbWrapper}>
      <Breadcrumb>
        {renderBreadcrumbs(breadcrumbState)}
      </Breadcrumb>
    </div>
  )
}

export default Breadcrumbs
